import {IConnectionSearchRequestPlus} from 'api/pociagi/interfaces';
import queryString from 'query-string';
import {getFullTimeFromDate, getFullDate, makeBetweenStationArr, makeTicketsArrFromQuery} from 'Utils/commonFunctions';
import {QueryStringData} from './QueryString.types';
import {format, parse} from 'date-fns';
const makeArrSingle = (label: string, arr: string[] | number[] | []) => {
  let str = '';
  const objects = [...arr];
  objects.forEach((el) => {
    str += `&${label}=${el}`;
  });
  return str;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const makeArrObj = (label: string, arr: any) => {
  let str = '';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  arr.forEach((el: any) => {
    let values = '';
    for (const key in el) {
      values += `${el[key]},`;
    }
    str += `&${label}=${values}`;
  });

  return str;
};
const formatUrlTime = (time: string) => {
  const parsedTime = time.length > 5 ? parse(time, 'HH:mm:ss', new Date()) : parse(time, 'HH:mm', new Date());
  return format(parsedTime, 'HH:mm');
};
export const makeQuerySearch = (obj: IConnectionSearchRequestPlus, obj2?: IConnectionSearchRequestPlus) => {
  const query = `?dwyj=${obj.dataWyjazdu}&swyj=${obj.stacjaWyjazdu}&sprzy=${obj.stacjaPrzyjazdu}${makeArrObj('sprzez', obj.stacjePrzez)}&polbez=${obj.polaczeniaBezposrednie}&polnaj=${obj.polaczeniaNajszybsze}&lpol=${obj.liczbaPolaczen}${makeArrSingle('kpoc', obj.kategoriePociagow)}${makeArrSingle('kprzew', obj.kodyPrzewoznikow)}${makeArrSingle('rodzm', obj.rodzajeMiejsc)}${makeArrSingle('typm', obj.typyMiejsc)}&cmax=${obj.czasNaPrzesiadkeMax}&cmin=${obj.czasNaPrzesiadkeMin}&brail=${obj.braille}&przy=${obj.przyjazd}&time=${formatUrlTime(obj.time)}&ticket50=${obj.ticket50}&ticket100=${obj.ticket100}`;

  let queryBack = '';

  if (obj2) {
    queryBack = `&backdwyj=${obj2.dataWyjazdu}&backswyj=${obj2.stacjaWyjazdu}&backsprzy=${obj2.stacjaPrzyjazdu}${makeArrObj('backsprzez', obj2.stacjePrzez)}&backpolbez=${obj2.polaczeniaBezposrednie}&backpolnaj=${obj2.polaczeniaNajszybsze}&backlpol=${obj2.liczbaPolaczen}${makeArrSingle('backkpoc', obj2.kategoriePociagow)}${makeArrSingle('backkprzew', obj2.kodyPrzewoznikow)}${makeArrSingle('backrodzm', obj2.rodzajeMiejsc)}${makeArrSingle('backtypm', obj2.typyMiejsc)}&backcmax=${obj2.czasNaPrzesiadkeMax}&backcmin=${obj2.czasNaPrzesiadkeMin}&backbrail=${obj2.braille}&backprzy=${obj2.przyjazd}&backtime=${formatUrlTime(obj2.time)}&backticket50=${obj2.ticket50}&backticket100=${obj2.ticket100}`;
  }

  return `${query}${queryBack}`;
};

export const makeObjectFromQueryString = (locationSearch: string) => {
  const {swyj, sprzy, time, dwyj, sprzez, przy, ticket50, ticket100} = queryString.parse(locationSearch);

  const initStationBetween = sprzez !== null ? (sprzez as string) : null;

  return {
    from: swyj ? parseInt(swyj as string) : 0,
    destination: sprzy ? parseInt(sprzy as string) : 0,
    time: time ? (time as string) : getFullTimeFromDate(new Date().toString()),
    date: dwyj ? (dwyj as string) : getFullDate(new Date().toString()),
    viaStations: initStationBetween ? makeBetweenStationArr(initStationBetween) : [],
    tripBack: 0,
    arrivalDeparture: przy ? parseInt(przy as string) === 1 : false,
    tickets50: ticket50 ? makeTicketsArrFromQuery(ticket50 as string) : [],
    tickets100: ticket100 ? makeTicketsArrFromQuery(ticket100 as string) : [],
  } as QueryStringData;
};

export const makeTripBackObjectFromQueryString = (locationSearch: string) => {
  const {backswyj, backsprzy, backtime, backdwyj, backsprzez, backprzy, backticket50, backticket100} =
    queryString.parse(locationSearch);

  const initStationBetween = backsprzez !== null ? (backsprzez as string) : null;

  return {
    from: backswyj ? parseInt(backswyj as string) : 0,
    destination: backsprzy ? parseInt(backsprzy as string) : 0,
    time: backtime ? (backtime as string) : null,
    date: backdwyj ? (backdwyj as string) : null,
    viaStations: initStationBetween ? makeBetweenStationArr(initStationBetween) : [],
    tripBack: 0,
    arrivalDeparture: backprzy ? parseInt(backprzy as string) === 1 : false,
    tickets50: backticket50 ? makeTicketsArrFromQuery(backticket50 as string) : [],
    tickets100: backticket100 ? makeTicketsArrFromQuery(backticket100 as string) : [],
  } as QueryStringData;
};
