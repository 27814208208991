import {Dispatch, SetStateAction, createContext, ReactNode, FC, useState} from 'react';
import {IConnectionSearchResponse} from 'api/pociagi/interfaces';
import {ISearchConnectionFormData} from '../interfaces/forms';

type SearchState = {
  children: ReactNode;
};

interface SearchInterface {
  query: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setQuery: any;
  searchFormFrom: ISearchConnectionFormData | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setSearchFormFrom: any;
  searchFrom: IConnectionSearchResponse | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setSearchFrom: any;
  searchTo: IConnectionSearchResponse | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setSearchTo: any;
  actualProgresBarStep: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setActualProgresBarStep: any;
  maxProgresBarStep: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setMaxProgresBarStep: any;
  isForeignStationSelected: boolean;
  setIsForeignStationSelected: (value: boolean) => void;
  systemNotification: ReactNode;
  setSystemNotification: Dispatch<SetStateAction<ReactNode>>;
  systemNotificationTitle: string;
  setSystemNotificationTitle: Dispatch<SetStateAction<string>>;
  isTechnologicalBreak: boolean;
  setIsTechnologicalBreak: Dispatch<SetStateAction<boolean>>;
  tripBack: boolean;
  setTripBack: Dispatch<SetStateAction<boolean>>;
}

export const SearchContext = createContext<SearchInterface>({
  query: '',
  setQuery: '',
  searchFormFrom: null,
  setSearchFormFrom: null,
  searchFrom: null,
  setSearchFrom: null,
  searchTo: null,
  setSearchTo: null,
  actualProgresBarStep: 0,
  setActualProgresBarStep: null,
  maxProgresBarStep: 0,
  setMaxProgresBarStep: null,
  isForeignStationSelected: false,
  setIsForeignStationSelected: () => {},
  systemNotification: '',
  setSystemNotification: () => {},
  systemNotificationTitle: '',
  setSystemNotificationTitle: () => {},
  isTechnologicalBreak: false,
  setIsTechnologicalBreak: () => {},
  tripBack: false,
  setTripBack: () => {},
});

const SearchProvider: FC<SearchState> = ({children}) => {
  const [query, setQuery] = useState('');
  const [searchFormFrom, setSearchFormFrom] = useState<ISearchConnectionFormData | null>(null);
  const [searchFrom, setSearchFrom] = useState<IConnectionSearchResponse | null>(null);
  const [searchTo, setSearchTo] = useState<IConnectionSearchResponse | null>(null);
  const [actualProgresBarStep, setActualProgresBarStep] = useState<number>(0);
  const [maxProgresBarStep, setMaxProgresBarStep] = useState<number>(0);
  const [isForeignStationSelected, setIsForeignStationSelected] = useState<boolean>(false);
  const [systemNotification, setSystemNotification] = useState<ReactNode>('');
  const [systemNotificationTitle, setSystemNotificationTitle] = useState<string>('');
  const [isTechnologicalBreak, setIsTechnologicalBreak] = useState<boolean>(false);
  const [tripBack, setTripBack] = useState(false);
  return (
    <SearchContext.Provider
      value={{
        query,
        setQuery,
        searchFormFrom,
        setSearchFormFrom,
        searchFrom,
        setSearchFrom,
        searchTo,
        setSearchTo,
        actualProgresBarStep,
        setActualProgresBarStep,
        maxProgresBarStep,
        setMaxProgresBarStep,
        isForeignStationSelected,
        setIsForeignStationSelected,
        systemNotification,
        setSystemNotification,
        isTechnologicalBreak,
        setIsTechnologicalBreak,
        systemNotificationTitle,
        setSystemNotificationTitle,
        tripBack,
        setTripBack,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export default SearchProvider;
