import {useState, FC} from 'react';
import Grid from '@mui/material/Grid';

import {IAmenities} from 'api/pociagi/interfaces';
import AmenietiesModal from '../../AmenitiesModal';

import S from './AmenitiesNewDesktop.module.scss';
import {useTranslation} from 'react-i18next';
import Icon from 'common/components/base/Icon';
import {FilterIcons} from '../FilterIcons';

interface Props {
  amenities: IAmenities;
  setAmenities: (e: IAmenities) => void;
}

const AmenitiesNewDesktop: FC<Props> = ({amenities, setAmenities}) => {
  const {t} = useTranslation();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <div className={S.container}>
      <Grid
        container
        sx={{
          maxHeight: '96px',
        }}
      >
        <Grid
          item
          xs={12}
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{
            padding: '32px 24px',
          }}
        >
          <Grid
            item
            sx={{
              height: '32px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <Grid
              item
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <FilterIcons />
            </Grid>
            <p className={S.text}>{t('17009')}</p>
          </Grid>
          <button
            type="button"
            className={S.changeBtn}
            aria-label={t('15002')}
            onClick={handleClickOpen}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleClickOpen();
              }
            }}
          >
            {t('13004')}
            <span className="hiddenLabel"> {t('17009')}</span>
            <Icon icon="pencil" />
          </button>
        </Grid>
      </Grid>
      {open && <AmenietiesModal open={open} setOpen={setOpen} amenities={amenities} setAmenities={setAmenities} />}
    </div>
  );
};
export default AmenitiesNewDesktop;
