import {FC, ReactNode} from 'react';
import {useStyledThemeState} from 'common/theme';
import {useTranslation} from 'react-i18next';

import S from './ConfirmationModal.module.scss';
import OrangeX from 'assets/orangeX.svg';
import ContrastX from 'assets/contrastX.svg';
import ConfirmButton from '../ConfirmButton/ConfirmButton';
import OutlineButton from '../OutlineButton/OutlineButton';
import ReactFocusLock from 'react-focus-lock';

interface Props {
  children: ReactNode;
  closeFn: (e: boolean) => void;
  confirmFn?: () => void;
  hideConfirmButton?: boolean;
  hideRejectButton?: boolean;
  customHeader?: string;
  customCancelBtnTxt?: string;
  customConfirmBtnTxt?: string;
  columnButtons?: boolean;
  fullSizeModal?: boolean;
}

const ConfirmationModal: FC<Props> = ({
  children,
  closeFn,
  confirmFn,
  hideConfirmButton,
  hideRejectButton,
  customHeader,
  customCancelBtnTxt,
  customConfirmBtnTxt,
  columnButtons,
  fullSizeModal,
}) => {
  const {t} = useTranslation();
  const {isContrastTheme} = useStyledThemeState();
  return (
    <ReactFocusLock returnFocus>
      <div className={S.ConfirmationModal}>
        <div className={`${S.ConfirmationModal_container} ${fullSizeModal ? S.fullSizeContainer : ''}`}>
          <div>
            <div className={S.ConfirmationModal_topRow}>
              <h1>{customHeader ? customHeader : t('22041')}</h1>
              <button
                aria-label={t('22042')}
                className={S.ConfirmationModal_closeButton}
                onClick={(e) => {
                  e.preventDefault();
                  closeFn(false);
                }}
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    closeFn(false);
                  }
                }}
              >
                <img src={isContrastTheme ? ContrastX : OrangeX} alt={t('24006')} />
              </button>
            </div>
            <div className={S.ConfirmationModal_centerRow}>{children}</div>
          </div>

          {!columnButtons ? (
            <div className={S.ConfirmationModal_bottomRow}>
              {!hideRejectButton && (
                <div className={S.ConfirmationModal_wrapper}>
                  <OutlineButton
                    text={customCancelBtnTxt ? customCancelBtnTxt : t('29004')}
                    path={() => closeFn(false)}
                  />
                </div>
              )}
              {!hideConfirmButton && confirmFn && (
                <div className={S.ConfirmationModal_wrapper}>
                  <ConfirmButton
                    text={customConfirmBtnTxt ? customConfirmBtnTxt : t('29005')}
                    execute={() => confirmFn()}
                  />
                </div>
              )}
            </div>
          ) : (
            <div className={S.btn_column}>
              {!hideConfirmButton && confirmFn && (
                <div className={S.ConfirmationModal_wrapper}>
                  <ConfirmButton
                    text={customConfirmBtnTxt ? customConfirmBtnTxt : t('29005')}
                    execute={() => confirmFn()}
                  />
                </div>
              )}
              {!hideRejectButton && (
                <div className={S.ConfirmationModal_wrapper}>
                  <OutlineButton
                    text={customCancelBtnTxt ? customCancelBtnTxt : t('29004')}
                    path={() => closeFn(false)}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </ReactFocusLock>
  );
};

export default ConfirmationModal;
