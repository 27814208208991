/* eslint-disable no-extra-boolean-cast */
import {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

import S from './ShoppingBasketCounterWithBadge.module.scss';

import {useShoppingBasket} from '../ShoppingBasketContext';
import {useStyledThemeState} from 'common/theme';

import ShoppingBasket from 'assets/Basket.svg';
import ShoppingBasketContrast from 'assets/BasketContrast.svg';
import ShoppingBasketGray from 'assets/ShoppingBasket/sb_basket_gray.svg';
import ClockIconRed from 'assets/clockRed.svg';

import Badge from 'components/common/Badge';
import {returnSecondsUntilDate} from 'Utils/commonFunctions';
import Timer from 'components/common/Timer/Timer';

interface Props {
  iconMode?: boolean;
  timeOutIconMode?: boolean;
}

const ShoppingBasketCounterWithBadge: FC<Props> = ({iconMode, timeOutIconMode}) => {
  const [secRemaining, setSecRemaining] = useState<number>(0);
  const {t} = useTranslation();
  const {
    itemsNumber,
    lessThan2MinutesLeft,
    basket,
    setLessThan2MinutesLeft,
    resetBasketOnTimeout,
    timeLimit,
    basketCancelled,
  } = useShoppingBasket();

  const {isContrastTheme} = useStyledThemeState();

  let timeObj: NodeJS.Timeout;

  const setTimeoutObj = (remainingSeconds: number) => {
    timeObj = setTimeout(() => setSecRemaining(remainingSeconds - 1), 1000);
  };

  const clearTimeoutObj = () => {
    clearTimeout(timeObj);
  };

  const returnBasketIcon = () => {
    let imgSrc = ShoppingBasket;

    if (!!basket?.waitingForPayment) {
      imgSrc = ShoppingBasketGray;
    }

    if (isContrastTheme) {
      imgSrc = ShoppingBasketContrast;
    }

    return <img src={imgSrc} alt={t('13102')} />;
  };

  const returnTimeLeftTextClassName = () => {
    if (!!lessThan2MinutesLeft) return S.text_error;
    if (!!basket?.waitingForPayment) return S.text_gray;

    return S.text_default;
  };

  const returnBgColorForBadge = () => {
    return !!basket?.waitingForPayment ? 'gray' : !lessThan2MinutesLeft ? 'orange' : 'red';
  };

  const resetTimer = () => {
    secRemaining > 0 && setSecRemaining(0);
    clearTimeoutObj();
  };

  useEffect(() => {
    if (iconMode) return;

    if (itemsNumber > 0 && !!timeLimit) {
      const realTimeLeft = returnSecondsUntilDate(timeLimit);

      if (secRemaining > 0) {
        if (realTimeLeft !== secRemaining) {
          setTimeoutObj(realTimeLeft);
        } else {
          setTimeoutObj(secRemaining);
        }
      }

      if (secRemaining <= 120 && secRemaining > 0 && !lessThan2MinutesLeft) {
        setLessThan2MinutesLeft(true);
      }

      if (realTimeLeft <= 0) {
        resetBasketOnTimeout();
        resetTimer();
      }

      if (secRemaining === 0 && realTimeLeft > 0) {
        setSecRemaining(realTimeLeft);
      }

      if (secRemaining > 120 && !!lessThan2MinutesLeft) {
        setLessThan2MinutesLeft(false);
      }
    } else {
      resetTimer();
    }

    return () => clearTimeoutObj();
  }, [secRemaining, itemsNumber, timeLimit]);

  if (iconMode) {
    return (
      <div className={`${S.menu_link} ${S.basket_ico}`}>
        {itemsNumber > 0 && (
          <span className={`${S.basket_badge_icon_mode}`}>
            <Badge littleMode value={itemsNumber} customBg={returnBgColorForBadge()} />
          </span>
        )}
        {returnBasketIcon()}
      </div>
    );
  }

  if (timeOutIconMode) {
    return (
      <div className={S.departureTimeContainer}>
        <img width={16} height={16} src={ClockIconRed} alt={t('17051')} />
        <p className={`${S.departureTimeLabel} ${S.text_error}`}>0:00</p>
        <span className={S.hidden} aria-atomic="true" aria-live="polite">
          ${t('22036')} 0:00
        </span>
      </div>
    );
  }

  return (
    <div className={S.flex_box}>
      <Link to="/koszyk" className={`${S.menu_link} ${S.basket_ico}`} aria-label="Koszyk zakupowy">
        {itemsNumber > 0 && !basketCancelled && (
          <span className={`${S.basket_badge}`}>
            <Badge value={itemsNumber} customBg={!!basket?.waitingForPayment ? 'gray' : 'orange'} />
          </span>
        )}
        {returnBasketIcon()}
      </Link>
      {!!timeLimit && itemsNumber > 0 && !basketCancelled && (
        <span className={`${S.timer_text} ${S.basket_wrap} ${returnTimeLeftTextClassName()}`}>
          {!!timeLimit ? <Timer deadline={timeLimit} /> : '0:00'}
        </span>
      )}
    </div>
  );
};

export default ShoppingBasketCounterWithBadge;
