/* eslint-disable @typescript-eslint/no-explicit-any */
import {useState, useEffect, useContext, useMemo} from 'react';
import {useLocation} from 'react-router-dom';
import queryString from 'query-string';

import {SearchContext} from 'Utils/SearchContext';
import {useData} from 'Utils/DataContex';

import InitialObject from './PurchasePathDesktop/AmenitiesNewDesktop/InitialObject';
import {IConnectionSearchResponse, IConnectionSearchViaStation} from 'api/pociagi/interfaces';
import {ISearchConnectionFormData} from 'interfaces/forms';
import api from 'api/pociagi/repository';
import moment from 'moment';
import {IConnectionSearchResultsList, IAmenities} from '../../../api/pociagi/interfaces';
import {IPriceCheckOffer} from 'api/sprzedaz/interfaces';
import {getTimeFromDate, returnStationByCode, returnTimeDiff} from 'Utils/commonFunctions';
import {makeBetweenStationArr} from '../../../Utils/commonFunctions';
import {useTranslation} from 'react-i18next';
import {TFunction} from 'i18next';
import {isForeignConnection, getStationByCode} from 'common/utils';
import {useAmenitiesActions, useAmenitiesState} from 'common/hooks/useAmenities';
import {Text} from 'common/components/base/Text';
import {useWindowResize} from 'common/hooks/screen';
import {formatDateToCetTimeZone, formatTimeToCetTimeZone} from 'common/utils/';

const useSearchLogic = (searchPage = false, exchangeSearch?: boolean) => {
  const {t} = useTranslation();
  const {isMobile} = useWindowResize();
  const {setTripBack} = useContext(SearchContext);
  const formatDate = (date: string): string => moment(date).format('yyyy-MM-DD');

  const location = useLocation();
  const {query, searchFrom, setSearchFrom, searchTo, setSearchTo} = useContext(SearchContext);

  const {stationsList} = useData();

  const {setContextAmenities} = useAmenitiesActions();
  const {contextAmenities} = useAmenitiesState();

  const parsed = queryString.parse(location.search);

  const amenitiesOnStart = contextAmenities ? {...contextAmenities} : {...InitialObject};

  const [amenities, setAmenities] = useState<IAmenities>({
    ...amenitiesOnStart,
  });

  const initStationBetween = parsed.sprzez as unknown as any;
  const initStationBetweenBack = parsed.backsprzez as unknown as any;

  const [searchFormData, setSearchFormData] = useState<ISearchConnectionFormData>({
    from: parsed.swyj ? parseInt(parsed.swyj as string) : 0,
    destination: parsed.sprzy ? parseInt(parsed.sprzy as string) : 0,
    time: parsed.time ? (parsed.time as string) : formatTimeToCetTimeZone(new Date()),
    date: parsed.dwyj ? (parsed.dwyj as string) : formatDateToCetTimeZone(new Date()),
    viaStations: initStationBetween ? makeBetweenStationArr(initStationBetween) : [],
    tripBack: 0,
    arrivalDeparture: parsed.przy ? parseInt(parsed.przy as string) === 1 : false,
  });

  const isExchangeMode = window.location.pathname.includes('/wymianabiletu');
  const isReservation = window.location.pathname.includes('/rezerwacje');
  const isTripBack = parsed.backswyj;
  const stationBetween = parsed.sprzez as unknown as any;
  const stationBetweenTripBack = parsed.backsprzez as unknown as any;
  const state = {
    from: parseInt(parsed.swyj as string),
    destination: parseInt(parsed.sprzy as string),
    time: parsed.time as string,
    date: parsed.dwyj as string,
    viaStations: stationBetween ? makeBetweenStationArr(stationBetween) : [],
    tripBack: !!parsed.backsprzy && !!parsed.backswyj ? 1 : 0,
    arrivalDeparture: searchFormData.arrivalDeparture,
  };
  const stateTripBack = {
    from: parseInt(parsed.backswyj as string),
    destination: parseInt(parsed.backsprzy as string),
    time: parsed.backtime as string,
    date: parsed.backdwyj as string,
    viaStations: stationBetweenTripBack ? makeBetweenStationArr(stationBetweenTripBack) : [],
    tripBack: 0,
    arrivalDeparture: searchFormData.arrivalDeparture,
  };

  const tomorrow = new Date(searchFormData.date);
  tomorrow.setDate(tomorrow.getDate() + 1);

  const [searchFormTripBack, setSearchFormTripBack] = useState<ISearchConnectionFormData>({
    from: parsed.backswyj ? parseInt(parsed.swyj as string) : 0,
    destination: parsed.backsprzy ? parseInt(parsed.sprzy as string) : 0,
    time: parsed.backtime ? (parsed.backtime as string) : '00:00',
    date: parsed.backdwyj ? (parsed.backdwyj as string) : formatDateToCetTimeZone(tomorrow),
    viaStations: initStationBetweenBack ? makeBetweenStationArr(initStationBetweenBack) : [],
    tripBack: 0,
    arrivalDeparture: parsed.backprzy ? parseInt(parsed.backprzy as string) === 1 : false,
  });

  const [searchResults, setSearchResults] = useState<IConnectionSearchResponse>();
  const [searchResultsTripBack, setSearchResultsTripBack] = useState<IConnectionSearchResponse | null>();
  const [searchResultsLoading, setSearchResultsLoading] = useState(false);
  const [noConnErrorState, setNoConnErrorState] = useState<string | undefined>();
  const [noConnTripBackErrorState, setNoConnTripBackErrorState] = useState<string | undefined>();

  const isForeignConnectionBySearchData = (data: ISearchConnectionFormData): boolean => {
    const {from, destination, viaStations} = data;
    const fromCode = getStationByCode(from, stationsList)?.kodEVA;
    const destinationCode = getStationByCode(destination, stationsList)?.kodEVA;

    const viaStationsCodes = viaStations
      .filter(({stacjaPrzez}) => stacjaPrzez !== 0)
      .map(({stacjaPrzez}) => getStationByCode(stacjaPrzez, stationsList)!.kodEVA);

    if (!fromCode || !destinationCode) return false;

    return isForeignConnection(fromCode, destinationCode, viaStationsCodes);
  };

  const isFromForeign = useMemo(
    () => isForeignConnectionBySearchData(searchFormData),
    [searchFormData.from, searchFormData.destination, searchFormData.viaStations, stationsList],
  );

  const isForeignTripBack = useMemo(
    () => isForeignConnectionBySearchData(searchFormTripBack),
    [searchFormTripBack.from, searchFormTripBack.destination, searchFormTripBack.viaStations, stationsList],
  );

  const isForeign = isFromForeign || isForeignTripBack;

  const getResults = async (
    from: number,
    destination: number,
    date: string,
    time: string,
    arrivalDepartureState: boolean,
    viaStations?: IConnectionSearchViaStation[],
    amenities?: IAmenities,
    siteTo?: boolean,
  ) => {
    const searchData = parsed.swyj
      ? {
          from: parsed.swyj ? parseInt(parsed.swyj as string) : 0,
          destination: parsed.sprzy ? parseInt(parsed.sprzy as string) : 0,
          arrivalDeparture: parsed.przy ? parseInt(parsed.przy as string) === 1 : false,
        }
      : searchFormData;

    const searchDataBack = parsed.backswyj
      ? {
          from: parsed.backswyj ? parseInt(parsed.swyj as string) : 0,
          destination: parsed.backsprzy ? parseInt(parsed.sprzy as string) : 0,
          arrivalDeparture: parsed.backprzy ? parseInt(parsed.backprzy as string) === 1 : false,
        }
      : searchFormTripBack;

    let results = await getSearchResults(from, destination, date, time, arrivalDepartureState, viaStations, amenities);

    if (results) {
      results = {
        ...results,
        kiedy: results.kiedy.slice(0, -3),
      };
      if (searchData.from === searchData.destination) {
        setSearchResults({polaczenia: [], kiedy: results.kiedy, bledy: []});
        setNoConnErrorState('18001');
        return;
      }
      if (siteTo) {
        setNoConnErrorState(undefined);
        setSearchResults(results);
        setSearchFrom(results);
        results.bledy.length > 0 && setNoConnErrorState('17008');
      } else {
        setNoConnTripBackErrorState(undefined);

        const baseItem = searchDataBack.arrivalDeparture
          ? getBaseItemIndexArrival(results.polaczenia, results.kiedy)
          : getBaseItemIndexDeparture(results.polaczenia, results.kiedy);

        if (baseItem.index > -1) {
          setSearchResultsTripBack(results);
          setSearchTo(results);
        } else {
          setSearchResultsTripBack({polaczenia: [], kiedy: results.kiedy, bledy: []});
          setNoConnTripBackErrorState('17008');

          return;
        }
      }
    }
  };

  useEffect(() => {
    const startSearchCheck = () => {
      if (exchangeSearch === undefined) return searchPage && location.search;

      return exchangeSearch;
    };

    if (startSearchCheck()) {
      const mapper = (arr: string[]) => {
        return arr.map((el) => parseInt(el));
      };
      const parsArr = (el: string[] | string) => {
        if (typeof el === 'string') {
          return [parseInt(el)];
        }
        return mapper(el);
      };

      if (parsed && !!parsed.sprzy && !!parsed.swyj) {
        setNoConnErrorState(undefined);
        if (parsed.kpoc && !Array.isArray(parsed.kpoc)) {
          parsed.kpoc = [parsed.kpoc];
        }

        const amenities = {
          polaczeniaBezposrednie: parseInt(parsed.polbez as string),
          kategoriePociagow: parsed.kpoc ? (parsed.kpoc as string[]) : [],
          kodyPrzewoznikow: parsed.kprzew ? parsArr(parsed.kprzew as string[]) : [],
          rodzajeMiejsc: parsed.rodzm ? parsArr(parsed.rodzm as string[]) : [],
          typyMiejsc: parsed.typm ? parsArr(parsed.typm as string[]) : [],
          braille: parseInt(parsed.brail as string),
          czasNaPrzesiadkeMin: parseInt(parsed.cmin as string),
        };

        setContextAmenities(amenities);
        setSearchFormData({...state});
        const result = async () => {
          await getResults(
            state.from,
            state.destination,
            state.date,
            state.time,
            state.arrivalDeparture,
            state.viaStations,
            amenities,
            true,
          );
        };

        if (query && query === location.search && searchFrom) {
          searchFrom.bledy.length > 0 && setNoConnErrorState('17008');
        } else if (isReservation || isExchangeMode || isMobile) {
          result();
        }
      }

      if (parsed && !!parsed.backsprzy && !!parsed.backswyj) {
        setNoConnTripBackErrorState(undefined);
        setTripBack(true);

        setSearchFormTripBack({...stateTripBack});

        const result = async () => {
          await getResults(
            stateTripBack.from,
            stateTripBack.destination,
            stateTripBack.date,
            stateTripBack.time,
            stateTripBack.arrivalDeparture,
            stateTripBack.viaStations,
            amenities,
          );
        };

        if (query && query === location.search && searchTo) {
          setSearchResultsTripBack(searchTo);
        } else if (isReservation || isExchangeMode || isMobile) {
          result();
        }
      }
    } else if (location.search === '' && searchPage) {
      const initial = {
        from: 0,
        destination: 0,
        time: getTimeFromDate(new Date().toString()),
        date: parsed.dwyj ? (parsed.dwyj as string) : formatDateToCetTimeZone(new Date()),
        viaStations: [],
        tripBack: 0,
        arrivalDeparture: false,
      };
      setContextAmenities({...InitialObject});
      setSearchFormData({...initial});
      setSearchFormTripBack({...initial});
      setSearchResults(undefined);
      setTripBack(false);
    }

    if (parsed && !!parsed.sprzy && !!parsed.swyj && !parsed.backsprzy && !parsed.backswyj) {
      setSearchResultsTripBack(null);
    }
  }, [location.search]);

  const getSearchResults = async (
    from: number,
    destination: number,
    date: string,
    time: string,
    arrivalDepartureState: boolean,
    viaStations?: any,
    amenities?: IAmenities,
  ) => {
    const departureDate = date + ' 00:00:00';
    const arrivalDate = date + ' 23:59:59';
    const viaStationsArray = viaStations ?? [];
    const dateFromCalendar = date + ' ' + time + ':00';
    const amenitiesObject = {
      polaczeniaBezposrednie: amenities ? amenities.polaczeniaBezposrednie : 0,
      kategoriePociagow: amenities ? amenities.kategoriePociagow : [],
      kodyPrzewoznikow: amenities ? amenities.kodyPrzewoznikow : [],
      rodzajeMiejsc: amenities ? amenities.rodzajeMiejsc : [],
      typyMiejsc: amenities ? amenities.typyMiejsc : [],
      braille: amenities ? amenities.braille : 0,
      czasNaPrzesiadkeMin: amenities ? amenities.czasNaPrzesiadkeMin : 3,
    };
    exchangeSearch && setSearchResultsLoading(true);
    const response = await api.connectionsSearch({
      urzadzenieNr: 0,
      metoda: '',
      dataWyjazdu: departureDate,
      dataPrzyjazdu: arrivalDate,
      stacjaWyjazdu: from,
      stacjaPrzyjazdu: destination,
      stacjePrzez: viaStationsArray,
      polaczeniaNajszybsze: 0,
      liczbaPolaczen: 0,
      czasNaPrzesiadkeMax: 1440,
      liczbaPrzesiadekMax: 2,
      ...amenitiesObject,
    });
    setSearchResultsLoading(false);

    if (response?.polaczenia) {
      response.kiedy = dateFromCalendar;
    }
    return response;
  };

  const checkIfStationFromMached = (id: number) => {
    const station = getStationByCode(id, stationsList);

    if (station?.kodEVA === parseInt(parsed.swyj as string)) {
      return !(station?.kodEVA === parseInt(parsed.swyj as string));
    } else if (
      parsed &&
      parsed.swyj &&
      !returnStationByCode(parseInt(parsed.swyj as string), stationsList).includes('dowolna')
    ) {
      return id !== parseInt(parsed.swyj as string);
    } else if (id === parseInt(parsed.swyj as string)) {
      return true;
    } else {
      return false;
    }
  };

  const checkIfStationToMached = (id: number) => {
    const station = getStationByCode(id, stationsList);

    if (station?.kodEVA === parseInt(parsed.sprzy as string)) {
      return !(station?.kodEVA === parseInt(parsed.sprzy as string));
    } else if (
      parsed &&
      parsed.sprzy &&
      !returnStationByCode(parseInt(parsed.sprzy as string), stationsList).includes('dowolna')
    ) {
      return id !== parseInt(parsed.sprzy as string);
    } else if (id === parseInt(parsed.swyj as string)) {
      return true;
    } else {
      return false;
    }
  };

  const returnPricesWithoutZeros = (prices: IPriceCheckOffer[]): IPriceCheckOffer[] => {
    let pricesWithoutZeros = [...prices].filter((item) => item.rodzajMiejscaKod === 1 || item.cena > 0);
    const pricesWithTypeCodeTwo = pricesWithoutZeros.filter((item) => item.cenaTypKod === 2);

    if (pricesWithoutZeros && pricesWithTypeCodeTwo) {
      const itemsToRemove: IPriceCheckOffer[] = [];
      pricesWithoutZeros.forEach((price) => {
        if (
          pricesWithTypeCodeTwo.find(
            (codeTwo) =>
              codeTwo.rodzajMiejscaKod === price.rodzajMiejscaKod &&
              codeTwo.klasa === price.klasa &&
              price.cenaTypKod !== 2,
          )
        ) {
          itemsToRemove.push(price);
        }
      });

      if (itemsToRemove.length > 0) {
        pricesWithoutZeros = [...pricesWithoutZeros].filter((el) => !itemsToRemove.includes(el));
      }
    }

    return pricesWithoutZeros;
  };

  const formatPrice = (
    t: TFunction,
    price: number,
    asterisk?: boolean,
    mobile?: boolean,
    showZeroPrice?: boolean,
    euroPrice?: number,
    isTooLate?: boolean,
  ) => {
    if (isTooLate) return t('17065');
    if (price === 0 && !showZeroPrice) return mobile ? t('18003') : t('17065');
    const fixedPrice = (price / 100).toFixed(2).replace('.', ',');
    const fixedEuroPrice = euroPrice ? (euroPrice / 100).toFixed(2).replace('.', ',') : null;

    if (asterisk !== undefined && asterisk) return fixedPrice + ' ' + t('24086') + '*';

    return (
      <>
        {fixedPrice} {t('24086')}
        {fixedEuroPrice ? (
          <Text variant={isMobile ? 'P4' : 'P2'} palette="gray.dark">
            &nbsp;({fixedEuroPrice} &euro;)
          </Text>
        ) : null}
      </>
    );
  };

  const sortListByDeparture = (arr: IConnectionSearchResultsList[]): IConnectionSearchResultsList[] => {
    const sortedList = [...arr].sort((prev, next) => {
      const date1 = new Date(next.dataWyjazdu).getTime();
      const date2 = new Date(prev.dataWyjazdu).getTime();

      return date2 - date1;
    });

    return sortedList;
  };

  const sortListByArrival = (arr: IConnectionSearchResultsList[]): IConnectionSearchResultsList[] => {
    const sortedList = [...arr].sort((prev, next) => {
      const date1 = new Date(next.dataPrzyjazdu).getTime();
      const date2 = new Date(prev.dataPrzyjazdu).getTime();

      return date2 - date1;
    });

    return sortedList;
  };

  const sortAndSkipItems = (arr: IConnectionSearchResultsList[], page: number): IConnectionSearchResultsList[] => {
    const sorted = sortListByDeparture(arr);
    const sliced = sorted.slice(page * 4, (page + 1) * 4);

    return sliced;
  };

  const returnOnlyLastThreeConnections = (sorted: IConnectionSearchResultsList[], baseTime: number): number => {
    const lastItems = sorted.length > 2 ? sorted.slice(-3) : [...sorted];

    if (lastItems.length > 0) {
      if (lastItems.some((item) => baseTime > new Date(item.dataWyjazdu).getTime()))
        return sorted.indexOf(lastItems[0]);
    }

    return -1;
  };

  const getBaseItemIndexDeparture = (
    arr: IConnectionSearchResultsList[],
    time: string,
  ): {index: number; isLast: boolean} => {
    const sorted = sortListByDeparture(arr);
    const timeNow = new Date().getTime();
    let baseTime = new Date(time).getTime();

    if (baseTime < timeNow) baseTime = timeNow;

    const lastThreeIndex = returnOnlyLastThreeConnections(sorted, baseTime);

    if (lastThreeIndex > -1) return {index: lastThreeIndex, isLast: true};

    const itemNearestTime = sorted.find((item) => {
      const itemTime = new Date(item.dataWyjazdu).getTime();

      if (itemTime >= baseTime) return item;
      return false;
    });

    const itemIndex = itemNearestTime ? sorted.indexOf(itemNearestTime) : -1;
    return {index: itemIndex, isLast: false};
  };

  const getBaseItemIndexArrival = (
    arr: IConnectionSearchResultsList[],
    time: string,
  ): {index: number; isLast: boolean} => {
    const sorted = sortListByArrival(arr);
    const baseTime = new Date(time).getTime();

    const lastThreeIndex = returnOnlyLastThreeConnections(sorted, baseTime);

    if (lastThreeIndex > -1) return {index: lastThreeIndex, isLast: true};

    const itemsBeforeArrival = sorted.filter((item) => {
      const itemArrTime = new Date(item.dataPrzyjazdu).getTime();

      if (itemArrTime <= baseTime) return item;

      return false;
    });

    if (itemsBeforeArrival) {
      const itemNearestTime =
        itemsBeforeArrival.length > 2 ? itemsBeforeArrival[itemsBeforeArrival.length - 2] : itemsBeforeArrival[0];
      return {index: itemNearestTime ? sorted.indexOf(itemNearestTime) : -1, isLast: false};
    }

    return {index: -1, isLast: false};
  };

  const sortAndFormatResultsList = (
    arr: IConnectionSearchResultsList[],
    arrivalDeparture: boolean,
    time: string,
    pagePrev: number,
    pageNext: number,
  ): {res: IConnectionSearchResultsList[]; min: number; max: number; laterInfo: boolean} => {
    const baseItem = arrivalDeparture ? getBaseItemIndexArrival(arr, time) : getBaseItemIndexDeparture(arr, time);

    if (baseItem.index < 0) return {res: [], min: 0, max: 0, laterInfo: true};

    const sorted = arrivalDeparture ? sortListByArrival(arr) : sortListByDeparture(arr);

    const minPage = Math.floor(baseItem.index / -3);
    const maxPage = Math.floor((sorted.length - baseItem.index) / 3);

    const minIndex = baseItem.index - pagePrev * -3;
    const maxIndex = baseItem.index + (pageNext + 1) * 3;

    return {
      res: sorted.slice(minIndex < 0 ? 0 : minIndex, maxIndex > sorted.length ? sorted.length : maxIndex),
      min: minPage,
      max: maxPage,
      laterInfo: baseItem.isLast,
    };
  };

  const convertTicketsToDiscountCodes = (tickets100: number, tickets50: number) => {
    const arr = [];

    if (tickets100 > 0) {
      for (let index = 0; index < tickets100; index++) {
        arr.push({kodZakupowyZnizki: 1010});
      }
    }

    if (tickets50 > 0) {
      for (let index = 0; index < tickets50; index++) {
        arr.push({kodZakupowyZnizki: 1710});
      }
    }

    return arr;
  };

  const checkIfFastest = (connectionTime: number, searchResults: IConnectionSearchResponse): boolean => {
    const fastest =
      searchResults?.polaczenia && [...searchResults.polaczenia].sort((a, b) => a.czasJazdy - b.czasJazdy);

    return fastest ? fastest[0]?.czasJazdy === connectionTime : false;
  };

  const formatDateToDayNameDayNrMonthName = (date: string) => {
    return moment(date).format('dddd, LL').slice(0, -4);
  };

  const formatDateToDayShortNameDayNrMonthName = (date: string) => {
    return moment(date).format('dd, LL').slice(0, -4);
  };

  // translation for week days
  const calendarWeekDataUK = [t('11104'), t('11098'), t('11099'), t('11100'), t('11101'), t('11102'), t('11103')];
  // translation for months
  const monthDataUk = [
    t('13035'),
    t('13036'),
    t('13037'),
    t('13038'),
    t('13039'),
    t('13040'),
    t('13041'),
    t('13042'),
    t('13043'),
    t('13044'),
    t('13045'),
    t('13046'),
  ];

  const newFormatDate = (date: string) => {
    const getWeekDay = calendarWeekDataUK[new Date(date).getDay()];
    const getMonth = monthDataUk[new Date(date).getMonth()];

    return `${getWeekDay} ${moment(date).format('DD')} ${getMonth}`;
  };

  const formatDateMinusDayToDayShortNameDayNrMonthName = (date: string) => {
    const newDate = moment(date).subtract(1, 'days').format('yyyy-MM-DD');

    return newFormatDate(newDate);
  };

  const formatDatePlusDayToDayShortNameDayNrMonthName = (date: string) => {
    const newDate = moment(date).add(1, 'days').format('yyyy-MM-DD');

    return newFormatDate(newDate);
  };

  const formatTripTime = (tripTime: number): string => {
    const hours = tripTime / 60;
    const rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    const rminutes = Math.round(minutes);

    return rminutes !== 0 ? rhours + 'h ' + rminutes + 'min ' : rhours + 'h ';
  };

  const substractOneHourFromDate = (date: string, time: string) => {
    return moment(date + ' ' + time)
      .subtract(1, 'hours')
      .format('HH:mm');
  };

  const addOneHourToDate = (date: string, time: string) => {
    return moment(date + ' ' + time)
      .add(1, 'hours')
      .format('HH:mm');
  };

  const substractOneDayFromDate = (date: string, time: string) => {
    return moment(date + ' ' + time)
      .subtract(1, 'days')
      .format('yyyy-MM-DD');
  };

  const addOneDayToDate = (date: string, time: string) => {
    return moment(date + ' ' + time)
      .add(1, 'days')
      .format('yyyy-MM-DD');
  };

  const returnDaysDiff = (date1: string, date2: string): number => {
    const firstDate = moment(new Date(date1).setHours(0, 0, 0, 0));
    const secondDate = moment(new Date(date2).setHours(0, 0, 0, 0));

    return moment.duration(firstDate.diff(secondDate)).asDays() ?? 0;
  };

  const returnDuration = (date1: string, date2: string): string => {
    const duration = returnTimeDiff(date1, date2);

    if (duration === 1) return t('18005');
    if (duration > 1 && duration < 5) return `${duration} ${t('18006')}`;
    if (duration >= 5) return `${duration} ${t('18007')}`;

    return t('18008');
  };

  const returnTicketNumber = () => {
    if (parsed && !!parsed.bsr && !!parsed.bnr) {
      return `${parsed.bsr as string}${parsed.bnr as string}`;
    }
    return '';
  };

  const decodeAmenities = (amenities: IAmenities): string => {
    const amenitiesStringArray = [] as string[];
    const placeKinds = amenities.rodzajeMiejsc as number[];
    const placeTypes = amenities.typyMiejsc as number[];

    amenities.braille && amenitiesStringArray.push(t('18009'));

    placeKinds.includes(2) && amenitiesStringArray.push(t('18010'));
    placeKinds.includes(3) && amenitiesStringArray.push(t('18011'));

    placeTypes.includes(52) && amenitiesStringArray.push(t('18012'));
    placeTypes.includes(24) && amenitiesStringArray.push(t('18013'));
    placeTypes.includes(6) && amenitiesStringArray.push(t('18014'));
    placeTypes.includes(4) && amenitiesStringArray.push(t('18015'));
    placeTypes.includes(3) && amenitiesStringArray.push(t('18016'));

    amenities.pokazFrekwencje && amenitiesStringArray.push(t('18017'));
    amenities.polaczeniaBezposrednie && amenitiesStringArray.push(t('18018'));

    amenities.fakeNumberToCounter?.includes(2) && amenitiesStringArray.push('29378');
    amenities.fakeNumberToCounter?.includes(3) && amenitiesStringArray.push(t('18020'));
    amenities.fakeNumberToCounter?.includes(4) && amenitiesStringArray.push(t('18021'));
    amenities.fakeNumberToCounter?.includes(5) && amenitiesStringArray.push(t('18022'));

    amenities.kategoriePociagow && amenities.kategoriePociagow.forEach((x) => amenitiesStringArray.push(x));

    if (amenitiesStringArray.length > 0) {
      const result = amenitiesStringArray.join(', ');
      return `${t('18022')}: ${result}`;
    }

    return '';
  };

  const updateSearchResults = async () => {
    setSearchResultsLoading(true);
    const results = await getSearchResults(
      state.from,
      state.destination,
      state.date,
      state.time,
      state.arrivalDeparture,
      state.viaStations,
      amenities,
    );

    setSearchResults(results);
    setSearchFrom(results);
    if (isTripBack) {
      const resultsTripBack = await getSearchResults(
        stateTripBack.from,
        stateTripBack.destination,
        stateTripBack.date,
        stateTripBack.time,
        stateTripBack.arrivalDeparture,
        stateTripBack.viaStations,
        amenities,
      );
      setSearchResultsTripBack(resultsTripBack);
      setSearchFormTripBack(stateTripBack);
    }
    setSearchResultsLoading(false);
  };

  return {
    getSearchResults,
    returnPricesWithoutZeros,
    formatPrice,
    sortListByDeparture,
    sortListByArrival,
    sortAndSkipItems,
    getBaseItemIndexDeparture,
    getBaseItemIndexArrival,
    sortAndFormatResultsList,
    checkIfFastest,
    convertTicketsToDiscountCodes,
    formatDate,
    formatDateToDayNameDayNrMonthName,
    formatDateMinusDayToDayShortNameDayNrMonthName,
    formatDatePlusDayToDayShortNameDayNrMonthName,
    formatDateToDayShortNameDayNrMonthName,
    newFormatDate,
    formatTripTime,
    substractOneHourFromDate,
    addOneHourToDate,
    substractOneDayFromDate,
    addOneDayToDate,
    returnDaysDiff,
    returnDuration,
    searchResults,
    setSearchResults,
    searchResultsTripBack,
    setSearchResultsTripBack,
    searchResultsLoading,
    amenities,
    setAmenities,
    searchFormData,
    setSearchFormData,
    searchFormTripBack,
    setSearchFormTripBack,
    noConnErrorState,
    noConnTripBackErrorState,
    returnTicketNumber,
    checkIfStationFromMached,
    checkIfStationToMached,
    decodeAmenities,
    updateSearchResults,
    isForeignConnection: isForeign,
  };
};

export default useSearchLogic;
