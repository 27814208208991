import {nativeEnum, z} from 'zod';
import {
  InternationalPersonType,
  InternationalOfferCode,
  InternationalTravelWithCareTaker,
  CareTaker,
  CareTakerTicket,
  SharedPlace,
} from './consts';
import {ApiErrorSchema} from '../../schemas';

export const InternationalOfferTypeSchema = z.nativeEnum(InternationalOfferCode);

export const InternationalPersonCodeSchema = z.nativeEnum(InternationalPersonType);

export const InternationalDiscountDescribeSchema = z
  .object({
    jezyk: z.string(),
    nazwa: z.string(),
    opis: z.string(),
  })
  .strict()
  .readonly();

export const InternationalDiscountSchema = z
  .object({
    oferta: InternationalOfferTypeSchema,
    kodZarzadu: z.number(),
    wspolneMiejsceMaxWiek: z.number(),
    opiekunWyborOdWiek: z.number(),
    opiekunWyborDoWiek: z.number(),
    osobaTypKod: InternationalPersonCodeSchema,
    aktualizacjaData: z.string(), // format: yyyy-MM-dd HH:mm:ss.SSS
    opisy: z.array(InternationalDiscountDescribeSchema),
  })
  .strict()
  .readonly();

export const InternationalDiscountsSchema = z.object({
  bledy: ApiErrorSchema,
  znizki: z.array(InternationalDiscountSchema),
});

export const InternationalTravelerSchema = z
  .object({
    dataUrodzenia: z.string().date().nullable(),
    podrozZOpiekunem: z.nativeEnum(InternationalTravelWithCareTaker),
    typOpiekuna: z.nativeEnum(CareTaker),
    typBiletuOpiekuna: nativeEnum(CareTakerTicket),
    biletSeriaOpiekun: z.string().optional(),
    biletNrOpiekun: z.number().optional(),
    wspolneMiejsce: z.nativeEnum(SharedPlace),
  })
  .refine(
    ({typBiletuOpiekuna, biletSeriaOpiekun, biletNrOpiekun}) =>
      (typBiletuOpiekuna === CareTakerTicket.normalTariff && !!biletNrOpiekun && !!biletSeriaOpiekun) ||
      (typBiletuOpiekuna === CareTakerTicket.otherTicket && !biletNrOpiekun && !biletSeriaOpiekun) || // optional validation
      typBiletuOpiekuna === CareTakerTicket.none,
  );

export const InternationalTravelersSchema = z.array(InternationalTravelerSchema);
