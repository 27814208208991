import {useTranslation} from 'react-i18next';
import packageJson from '../../../../package.json';
import S from './VersionInfo.module.scss';
import {env} from 'env';

const VersionInfo = () => {
  const {t} = useTranslation();
  return (
    <div className={S.versionInfo_container}>
      {t('29286')} {packageJson.version} {`(${env.REACT_APP_DEVICE_NUMBER})`}
    </div>
  );
};

export default VersionInfo;
