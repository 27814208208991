import {FC, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';

import useMyInvoice from './useMyInvoice';
import useMyTickets from '../MyTickets/useMyTickets';
import MyProfileHeader from 'components/common/MyProfileHeader';
import ProfilePagination from '../ProfilePagination';
import SingleInvoice from './SingleInvoice';
import Loader from 'components/common/Loader/Loader';

import style from '../MyTickets/MyTicketsMobilet.module.scss';
import {PobierzIdentyfikatoryFakturFakturaOdp} from 'api/faktury/interfaces';
import {useTranslation} from 'react-i18next';
interface Props {
  navigateLink?: string;
}

const MyInvoicesMobile: FC<Props> = ({navigateLink}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {
    searchPhase,
    setSearchPhase,
    page,
    setPage,
    returnActiveFiltersArr,
    search,
    error,
    setError,
    errorCounter,
    setErrorCounter,
  } = useMyTickets();

  const {tickets} = useMyInvoice();
  const filteredTickets = useMemo(() => {
    if (!tickets) return;
    return returnActiveFiltersArr(search(tickets))
      .filter((ticket) => ticket.ticketDetail.bilety.length > 0)
      .filter((_, index) => {
        return index >= 10 * (page - 1) && index < 10 * page;
      });
  }, [tickets, page, searchPhase]);
  return (
    <section className={style.current_tickets__container}>
      <MyProfileHeader text={t('10017')} closeAction={() => navigate(navigateLink ?? '/')} />
      <input
        type="text"
        placeholder={t('29185')}
        className={style.current_tickets__search}
        onChange={(e) => {
          setSearchPhase(e.target.value);
        }}
      />
      {error && (
        <div className={`col-sm-12 col-12 ${style.error_crontainer}`}>
          <p>{t('29168')}</p>
          <div className={style.ticket_main_data_right_column}>
            <button
              className={`${style.outline_button}`}
              onClick={(e) => {
                e.preventDefault();
                setError(false);
                setErrorCounter(errorCounter + 1);
              }}
            >
              {t('29169')}
            </button>
          </div>
        </div>
      )}
      {!tickets && !error && (
        <div className={`col-sm-12 col-12 ${style.error_crontainer}`}>
          <Loader />
        </div>
      )}
      {filteredTickets?.map((el) => (
        <div key={`${el.ticketInfo.biletNr}_${el.ticketInfo.typeOfNote}`} className={style.singleInvoice}>
          <SingleInvoice
            ticket={el.ticketInfo as unknown as PobierzIdentyfikatoryFakturFakturaOdp}
            details={el.ticketDetail}
          />
        </div>
      ))}
      {tickets && !!tickets.length && (
        <ProfilePagination
          page={page}
          setPage={setPage}
          toDisplay={
            returnActiveFiltersArr(search(tickets)).filter((el) => el.ticketDetail.bilety[0]?.fakturaNr).length
          }
          perPage={10}
        />
      )}
    </section>
  );
};

export default MyInvoicesMobile;
