import {InputWrapper, Separator, TimeInput, TimeManipulationButton} from './MinimumTransferTimeInput.style';
import {FC} from 'react';
import {useWindowResize} from 'common/hooks/screen';
import {useTranslation} from 'react-i18next';
import {useMinimumTransferTime} from './useMinimumTransferTime';
import {UNICODE_SYMBOLS} from 'common/consts';

interface MinimumTransferTimeInputProps {
  temporaryMinimumMinutes: number;
  onTemporaryMinutesChange: (e: number) => void;
}

export const MinimumTransferTimeInput: FC<MinimumTransferTimeInputProps> = ({
  temporaryMinimumMinutes,
  onTemporaryMinutesChange,
}) => {
  const {isMobile} = useWindowResize();
  const {t} = useTranslation();
  const {
    hours,
    minutes,
    handleMinutesInput,
    handleHoursInput,
    subtractTime,
    addTime,
    isAddButtonDisabled,
    isSubtractButtonDisabled,
    setMinimumMinutes,
  } = useMinimumTransferTime({
    initialMinutes: temporaryMinimumMinutes,
    onChange: onTemporaryMinutesChange,
  });

  return (
    <InputWrapper isMobile={isMobile}>
      <TimeManipulationButton
        isDisabled={isSubtractButtonDisabled}
        disabled={isSubtractButtonDisabled}
        onClick={subtractTime}
        aria-label={t('15050')}
        aria-disabled={isSubtractButtonDisabled}
      >
        {UNICODE_SYMBOLS.MINUS}
      </TimeManipulationButton>

      <div>
        <label htmlFor="hours" className="sr-only">
          {t('29536')}
        </label>
        <TimeInput
          id="hours"
          min={0}
          max={23}
          value={String(hours).padStart(2, '0')}
          onChange={(e) => handleHoursInput(Number(e.target.value))}
          type="number"
          name="hours"
          aria-label={t('15051')}
        />
      </div>

      <span>h</span>

      <Separator>:</Separator>

      <div>
        <label htmlFor="minutes" className="sr-only">
          {t('29537')}
        </label>
        <TimeInput
          id="minutes"
          min={0}
          max={59}
          value={String(minutes).padStart(2, '0')}
          onChange={(e) => handleMinutesInput(Number(e.target.value))}
          type="number"
          name="minutes"
          step={5}
          aria-label={t('15052')}
          onBlur={() => minutes < 3 && setMinimumMinutes()}
        />
      </div>

      <span>min</span>

      <TimeManipulationButton
        isDisabled={isAddButtonDisabled}
        disabled={isAddButtonDisabled}
        onClick={addTime}
        aria-label={t('15053')}
        aria-disabled={isAddButtonDisabled}
      >
        {UNICODE_SYMBOLS.PLUS}
      </TimeManipulationButton>
    </InputWrapper>
  );
};
