import {PlaceTypeCode} from 'common/types';

export const PLACE_TYPE_CODES = {
  NOT_SELECTED: -1,
  ANY: 0,
  COMPARTMENT: 1,
  WITHOUT_COMPARTMENT: 2,
  CHILD_UP_TO_6: 3,
  LIMITED_MOBILITY: 4,
  TABLE: 5,
  WHEELCHAIR: 6,
  THREE_SEATER_COUCHETTE: 12,
  FOUR_SEATER_COUCHETTE: 13,
  SIX_PEOPLE_COUCHETTE: 14,
  DISABILITY_COUCHETTE: 15,
  PRIVATE_COUCHETTE: 16,
  MANAGER: 21,
  BIKE: 24,
  WITH_CHILD: 50,
  WITH_DISABILITY: 51,
  SILENCE_ZONE: 52,
  CAREGIVER: 53,
  COMFORT_ZONE_2: 91,
  SINGLE_BED: 101,
  DOUBLE_BED: 102,
  TRIPLE_BED: 103,
  T4_BED: 104,
  DISABILITY_BED: 108,
  T2_BED: 109,
  SINGLE_DELUXE_BED: 170101,
  DOUBLE_DELUXE_BED: 170102,
  TRIPLE_DELUXE_BED: 170103,
} as const;

export const COUCHETTE_PLACES = [
  PLACE_TYPE_CODES.THREE_SEATER_COUCHETTE,
  PLACE_TYPE_CODES.FOUR_SEATER_COUCHETTE,
  PLACE_TYPE_CODES.SIX_PEOPLE_COUCHETTE,
  PLACE_TYPE_CODES.DISABILITY_COUCHETTE,
  PLACE_TYPE_CODES.PRIVATE_COUCHETTE,
] as const satisfies PlaceTypeCode[];

export const SLEEPING_PLACES = [
  PLACE_TYPE_CODES.SINGLE_BED,
  PLACE_TYPE_CODES.DOUBLE_BED,
  PLACE_TYPE_CODES.TRIPLE_BED,
  PLACE_TYPE_CODES.T4_BED,
  PLACE_TYPE_CODES.DISABILITY_BED,
  PLACE_TYPE_CODES.T2_BED,
  PLACE_TYPE_CODES.SINGLE_DELUXE_BED,
  PLACE_TYPE_CODES.DOUBLE_DELUXE_BED,
  PLACE_TYPE_CODES.TRIPLE_DELUXE_BED,
] as const satisfies PlaceTypeCode[];

export const SELECTION_PLACES = {
  DEFAULT: 0,
  NEXT_TO: 1,
  INDICATED: 2,
  NO_PLACE: 3,
} as const;
