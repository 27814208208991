import {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {differenceInSeconds, isAfter, parseISO, formatISO} from 'date-fns';
import {utcToZonedTime} from 'date-fns-tz';

import {useNavigate} from 'react-router-dom';
import {useStyledThemeState} from 'common/theme';

import S from './PaymentDeadlineTimer.module.scss';
import clockIcon from 'assets/clock.svg';
import clockIconContrast from 'assets/clockContrast.svg';
import ConfirmationModal from 'components/common/ConfirmationModal/ConfirmationModal';
import {useShoppingBasket} from '../../ShoppingBasket/ShoppingBasketContext';
import Timer from 'components/common/Timer/Timer';

interface Props {
  onPaymentDeadline?: () => void;
  isPayLater?: boolean;
  purchaseDeadline?: string;
}

const PaymentDeadlineTimer: FC<Props> = ({onPaymentDeadline, isPayLater, purchaseDeadline}) => {
  const {isContrastTheme} = useStyledThemeState();
  const {basket} = useShoppingBasket();
  const [secRemaining, setSecRemaining] = useState(0);
  const [checked, setChecked] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();

  const [timeDescription, setTimeDescription] = useState('');
  const {t} = useTranslation();

  const timeFormat = (time: number) => {
    const firstStep = 5;
    const secondStep = 1;
    if (time >= firstStep) return t('18007').toString();
    else if (time < firstStep && time > secondStep) return t('18007').toString();
    else if (time === firstStep) return t('22034').toString();
    else return t('22035').toString();
  };

  let timeObj: NodeJS.Timeout;

  useEffect(() => {
    const currentDateInCetTimezone = utcToZonedTime(new Date(), 'Europe/Warsaw');
    if (!isPayLater && purchaseDeadline) {
      const stored = sessionStorage.getItem('payment-deadline');
      if (stored && !isAfter(currentDateInCetTimezone, parseISO(stored))) {
        setSecRemaining(differenceInSeconds(parseISO(stored), currentDateInCetTimezone));
      } else {
        const deadline = new Date(purchaseDeadline);
        timeObj && clearTimeout(timeObj);
        setSecRemaining(differenceInSeconds(deadline, currentDateInCetTimezone));
        sessionStorage.setItem('payment-deadline', formatISO(deadline));
      }
      setChecked(true);
    }
    if (isPayLater) {
      const stored = localStorage.getItem('payLaterTicket');
      if (stored) {
        const data = JSON.parse(stored);
        const deadline = data?.ticketFrom?.czasNaOplacenie;
        if (deadline && !isAfter(currentDateInCetTimezone, parseISO(deadline))) {
          setSecRemaining(differenceInSeconds(parseISO(deadline), currentDateInCetTimezone));
        }
      }
      setChecked(true);
    }
  }, []);

  useEffect(() => {
    const isStored = isPayLater
      ? !!localStorage.getItem('payLaterTicket')
      : !!sessionStorage.getItem('payment-deadline');

    if (secRemaining > 0 && isStored) {
      timeObj = setTimeout(() => setSecRemaining(secRemaining - 1), 1000);
    }

    if (secRemaining) {
      if (secRemaining % 60 === 0) {
        setTimeDescription(`${t('22036')} ${secRemaining / 60} ${timeFormat(secRemaining / 60)}`);
      } else if (secRemaining < 60 && secRemaining > 0 && secRemaining % 20 === 0) {
        setTimeDescription(`${t('22036')} ${secRemaining / 60} ${timeFormat(secRemaining / 60)}`);
      }
    }

    if (secRemaining <= 0 && checked) {
      setTimeDescription(t('22037'));
      setOpenModal(true);
    }
  }, [secRemaining]);

  const getRemainingTime = (): string => {
    const secToDisplay = secRemaining % 60;
    const minRemaining = (secRemaining - secToDisplay) / 60;
    const minToDisplay = minRemaining % 60;
    const formattedSec = secToDisplay < 10 ? `0${secToDisplay}` : `${secToDisplay}`;
    const formattedMin = minToDisplay < 10 ? `0${minToDisplay}` : `${minToDisplay}`;
    return `${formattedMin}:${formattedSec}`;
  };

  const handleTimeEnd = () => {
    if (onPaymentDeadline) {
      onPaymentDeadline();
    } else {
      sessionStorage.removeItem('payment-deadline');
    }
    navigate('/');
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div className={S.departureTimeContainer}>
        <img width={16} height={16} src={isContrastTheme ? clockIconContrast : clockIcon} alt={t('17051')} />
        <p className={S.departureTimeLabel}>
          {!!basket && basket?.items.length > 0 ? <Timer deadline={basket.timeLimit} /> : checked && getRemainingTime()}
        </p>
        <span className={S.hidden} aria-atomic="true" aria-live="polite">
          {timeDescription}
        </span>
      </div>
      {openModal && (
        <div className={S.confirmModal}>
          <ConfirmationModal
            closeFn={handleTimeEnd}
            confirmFn={handleTimeEnd}
            customConfirmBtnTxt={t('17091')}
            hideRejectButton
          >
            <p>{t('22039')}</p>
          </ConfirmationModal>
        </div>
      )}
    </>
  );
};

export default PaymentDeadlineTimer;
