import Icon from 'common/components/base/Icon';
import {Icons} from './FilterIcons.style';

export const FilterIcons = () => {
  return (
    <Icons>
      <Icon variant="medium" icon="trainOnRails" />
      <Icon variant="large" icon="manOnArmchair" />
      <Icon variant="medium" icon="manOnWheelChair" />
    </Icons>
  );
};
