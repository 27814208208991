import {FC, useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import FullPageContainer from '../FullPageContainer/FullPageContainer';
import {modifySingleChars} from 'Utils/commonFunctions';
import {Element as ScrollTarget, scroller} from 'react-scroll';
import {useStyledThemeState} from 'common/theme';

import S from './FooterDesktop.module.scss';

import EcoTrip from '../EcoTrip';
import Rodo from '../Rodo';
import footerBcg from 'assets/layout/footerBcg.png';
import {ReactComponent as EuBanner1} from 'assets/eu1.svg';
import {ReactComponent as EuBanner2} from 'assets/eu2.svg';
import BIP2 from 'assets/bip-na-www-1.png';
import ArrowBottom from 'assets/orangeArrowDown.svg';
import ContrastArrowBottom from 'assets/contrastArrowBottom.svg';

export const scrollToBetaInfo = () => {
  scroller.scrollTo('betaInfo', {
    duration: 800,
    delay: 0,
    offset: 0,
    smooth: true,
  });
};

const FooterDesktop: FC = () => {
  const {t} = useTranslation();
  const [betaInfoExpanded, setBetaInfoExpanded] = useState<boolean>(false);
  const {isContrastTheme} = useStyledThemeState();
  const location = useLocation();

  useEffect(() => {
    if (location.hash.includes('betaInfo')) {
      setBetaInfoExpanded(true);
    }
  }, [location]);

  return (
    <footer
      className={S.footerWrapper}
      style={{
        backgroundImage: `url(${
          location.pathname !== '/' &&
          location.pathname !== '/wyszukiwanie' &&
          location.pathname !== '/rezerwacje' &&
          location.pathname !== '/rezerwacje/wyszukiwanie' &&
          location.pathname !== '/polaczenia-miedzynarodowe' &&
          !location.pathname.includes('/profil')
            ? footerBcg
            : ''
        })`,
      }}
    >
      <FullPageContainer>
        <div className={S.footerInnerWrapper}>
          <div className="grid">
            <div className="col-sm-12 col-12">
              <EcoTrip />
            </div>
          </div>
          <div className={S.divider} />
          <div className="grid">
            <div className="col-5">
              <h2 className={S.footerTextHeader}>{t('10034')}</h2>
              <p className={S.footerText}>{modifySingleChars(t('10031'))}</p>
              <ScrollTarget name="betaInfo" />
              <div className={S.betaInfoContainer} id="betaInfo" role="region">
                <span id="betaInfoDescription" className="sr-only">
                  {betaInfoExpanded ? t('10035') : t('10036')}{' '}
                </span>
                <div className={S.betaInfoContainer_header}>
                  <button
                    aria-label={betaInfoExpanded ? t('10035') : t('10036')}
                    onClick={() => setBetaInfoExpanded(!betaInfoExpanded)}
                    className={`${S.betaBtn} ${S.specialBtn}`}
                    aria-expanded={betaInfoExpanded}
                  >
                    <h2 className={S.footerTextHeader}>{t('10037')}</h2>
                    <img
                      aria-hidden="true"
                      className={`${betaInfoExpanded ? S.rotateArrow : ''}`}
                      src={isContrastTheme ? ContrastArrowBottom : ArrowBottom}
                      alt={betaInfoExpanded ? t('10038') : t('10039')}
                    />
                  </button>
                </div>
                <div className={`${S.betaInfoContainer_info} ${betaInfoExpanded ? S.expanded : ''}`}>
                  <p className={S.footerText}>{t('10040')}</p>
                  <ul>
                    <li>{t('10041')}</li>
                    <li>{t('10059')}</li>
                    <li>{t('10042')}</li>
                  </ul>
                  <p>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t('10043'),
                      }}
                    />
                  </p>
                </div>
              </div>
              <a
                href="https://www.intercity.pl/pl/deklaracja-dostepnosci.html"
                className={S.declaration}
                aria-label={t('10044')}
              >
                {t('10045')}
              </a>
            </div>
            <div className={S.colRight}>
              <p className={S.footerText}>{modifySingleChars(t('10032'))}</p>
            </div>
          </div>
          <div className="grid">
            <div className="col-6">
              <div className={S.euBanner}>
                <EuBanner1 aria-label={t('10046')} />
                {isContrastTheme ? (
                  <EuBanner2 aria-label={t('10047')} className={S.euBannerContrast} />
                ) : (
                  <EuBanner2 aria-label={t('10047')} />
                )}
                <img src={BIP2} alt={t('10049')} />
              </div>
            </div>
            <div className="col-6">
              <Rodo />
            </div>
          </div>
          <p className={S.footerTechInfo}>{t('10054')}</p>
        </div>
      </FullPageContainer>
    </footer>
  );
};

export default FooterDesktop;
