import {formatInTimeZone, utcToZonedTime} from 'date-fns-tz';
const timeZone = 'Europe/Warsaw';

export const formatDateToCetTimeZone = (date: Date) => {
  const transformedDate = formatInTimeZone(date, timeZone, 'yyyy-MM-dd');
  return transformedDate;
};
export const formatTimeToCetTimeZone = (date: Date) => {
  const transformedDate = formatInTimeZone(date, timeZone, 'HH:mm');
  return transformedDate;
};
export const formatDateObjectToCetTimezone = (date: Date) => {
  const transformedDate = utcToZonedTime(date, timeZone);
  return transformedDate;
};
