import {FC, useMemo} from 'react';

import SingleInvoice from './SingleInvoice';
import ProfilePagination from '../ProfilePagination';
import SearchAndFilter from '../SearchAndFilter';
import NoDataToDisplay from '../NoDataToDisplay/NoDataToDisplay';
import MyProfileHeader from 'components/common/MyProfileHeader';
import useMyTickets from '../MyTickets/useMyTickets';
import Loader from 'components/common/Loader/Loader';
import {useNavigate} from 'react-router-dom';
import useMyInvoice from './useMyInvoice';

import {PobierzIdentyfikatoryFakturFakturaOdp} from 'api/faktury/interfaces';

import style from './MyInvoices.module.scss';
import {useTranslation} from 'react-i18next';
interface Props {
  navigateLink?: string;
}

const MyInvoices: FC<Props> = ({navigateLink}) => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {
    searchPhase,
    setSearchPhase,
    filter,
    setFilter,
    page,
    setPage,
    returnActiveFiltersArr,
    search,
    error,
    setError,
    errorCounter,
    setErrorCounter,
  } = useMyTickets();

  const {tickets, pending} = useMyInvoice();

  const invoiceFilters = [
    {
      value: '26004',
      label: '26004',
      shortLabel: '26004',
      showArr: [1, 2, 3],
    },
    {
      value: '29186',
      label: '29186',
      shortLabel: '29186',
      showArr: [1, 2, 3],
    },
    {
      value: '29187',
      label: '29187',
      shortLabel: '29187',
      showArr: [1, 2, 3],
    },
    {
      value: '29188',
      label: '29188',
      shortLabel: '29188',
      showArr: [1, 2, 3],
    },
    {
      value: '29189',
      label: '29189',
      shortLabel: '29189',
      showArr: [1, 2, 3],
    },
    {
      value: '29161',
      label: '29162',
      shortLabel: '29161',
      showArr: [1, 2, 3],
    },
    {
      value: '29163',
      label: '29164',
      shortLabel: '29163',
      showArr: [1, 2, 3],
    },
  ];

  const GetInvoicesNumber = () => {
    return (
      !!tickets &&
      tickets.filter(
        (el) =>
          el.ticketDetail.bilety[0]?.fakturaNr ||
          el.ticketDetail.bilety[0]?.fakturaKorektaNr ||
          el.ticketDetail.bilety[0]?.notaKorygujacaNr,
      ).length
    );
  };
  const filteredTickets = useMemo(() => {
    if (!tickets) return;
    return returnActiveFiltersArr(search(tickets))
      .filter((ticket) => ticket.ticketDetail.bilety.length > 0)
      .filter((_, index) => {
        return index >= 10 * (page - 1) && index < 10 * page;
      });
  }, [tickets, page, filter, searchPhase]);

  return (
    <section className={`grid col-sm-12 col-12 ${style.myinvoices_container}`}>
      <MyProfileHeader text={t('10017')} closeAction={() => navigate(navigateLink ?? '/')} />
      {error && (
        <div className={`col-sm-12 col-12 ${style.errorContainer}`}>
          <p>{t('29168')}</p>
          <div className={style.errorBtnWrapper}>
            <button
              className={`${style.outlineButton}`}
              onClick={(e) => {
                e.preventDefault();
                setError(false);
                setErrorCounter(errorCounter + 1);
              }}
            >
              {t('29169')}
            </button>
          </div>
        </div>
      )}
      {!!tickets && (
        <>
          <SearchAndFilter
            dataToFilter={tickets.length}
            placeholderText={t('29185')}
            value={searchPhase}
            setValue={(e: string) => {
              setSearchPhase(e);
              setPage(1);
            }}
            filter={filter}
            setFilter={setFilter}
            forInvoices
            filterList={invoiceFilters}
          />
          {tickets && !GetInvoicesNumber() && !error && !pending ? (
            <NoDataToDisplay text={t('29184')} imageTicket="document" />
          ) : (
            <div className="col-sm-12 col-12">
              {filteredTickets?.map((el) => (
                <div key={`${el.ticketInfo.biletNr}_${el.ticketInfo.typeOfNote}`} className={style.singleInvoice}>
                  <SingleInvoice
                    ticket={el.ticketInfo as unknown as PobierzIdentyfikatoryFakturFakturaOdp}
                    details={el.ticketDetail}
                  />
                </div>
              ))}
              {tickets?.length ? (
                <ProfilePagination
                  page={page}
                  setPage={setPage}
                  toDisplay={
                    returnActiveFiltersArr(search(tickets)).filter((el) => el.ticketDetail.bilety[0]?.fakturaNr).length
                  }
                  perPage={10}
                />
              ) : null}
            </div>
          )}
        </>
      )}
      {pending && !error && (
        <div className={`col-sm-12 col-12 ${style.errorContainer}`}>
          <Loader />
        </div>
      )}
    </section>
  );
};

export default MyInvoices;
