import {Fragment, FC} from 'react';
import {
  getFullTimeFromDate,
  getFullDate,
  makeTimeOfTravel,
  returnCarrierLogo,
  returnStationByCode,
} from 'Utils/commonFunctions';
import {isSeasonalOfferCode} from 'common/utils';
import {IGenerateTicketResponse} from 'api/sprzedaz/interfaces';
import {useData} from 'Utils/DataContex';

import ArrowRight from 'assets/orangeArrowRight.svg';
import ArrowRightWhite from 'assets/whiteArrowRightNarrow.svg';
import ArrowRightContrast from 'assets/contrastArrowRight.svg';
import {useStyledThemeState} from 'common/theme';
import style from './MyTickets.module.scss';
import {useTranslation} from 'react-i18next';
interface Props {
  ticket: IGenerateTicketResponse;
  fullPrice: string;
}

const MyTicketsSingleTicketUnpaidMobileInnerData: FC<Props> = ({ticket, fullPrice}) => {
  const {t} = useTranslation();
  const {theme, isContrastTheme} = useStyledThemeState();
  const {stationsList} = useData();

  const {odcinki, transakcjeNr} = ticket;
  const isSeasonal = isSeasonalOfferCode(transakcjeNr?.[0]?.oferta);
  return (
    <>
      <div className={`${style.ticket_row}`}>
        <div className={style.left_column_little}>
          <p className={style.blue_text}>{t(isSeasonal ? '29398' : '11035')}</p>
          <p className={style.navy_text_big}>
            {`${getFullDate(ticket?.odcinki[0].wyjazdData.toString())}${isSeasonal ? ` - ${getFullDate(ticket?.odcinki[0].przyjazdData.toString())}` : ''}`}
          </p>
        </div>
        {!isSeasonal && (
          <div className={style.left_column_little}>
            <p className={style.blue_text}>
              {makeTimeOfTravel(
                ticket?.odcinki[0].wyjazdData,
                ticket?.odcinki[ticket?.odcinki.length - 1].przyjazdData.toString(),
              )}
            </p>
            <div className={style.time_box}>
              <p className={style.navy_text_big}>{getFullTimeFromDate(ticket?.odcinki[0].wyjazdData)}</p>
              <img
                src={isContrastTheme ? ArrowRightWhite : ArrowRight}
                style={{
                  margin: '0 5px',
                }}
                alt={t('11027')}
              />
              <p className={style.navy_text_big}>
                {getFullTimeFromDate(ticket?.odcinki[ticket?.odcinki.length - 1].przyjazdData.toString())}
              </p>
            </div>
          </div>
        )}
        <div className={style.left_column_little}>
          <p className={style.blue_text}>
            {t('17021')} {ticket?.odcinki[0]?.klasa}
          </p>
          <p className={style.navy_text_big}>{`${fullPrice}${t('24086')}`}</p>
        </div>
      </div>

      {odcinki.length > 0 && !!odcinki[0].stacjaOdKod && (
        <>
          <div className={`${style.ticket_row} ${style.blue_text}`}>{t('17028')}</div>
          <div className={`${style.ticket_row} ${style.station_list_box}`}>
            {ticket.odcinki.map((route, index) => (
              <Fragment key={route.pociagNr}>
                {route?.stacjaOdKod !== ticket.odcinki[index - 1]?.stacjaDoKod ? (
                  <>
                    <span className={`${style.regular_text} ${style.bold_text}`}>
                      {returnStationByCode(route.stacjaOdKod, stationsList)}
                    </span>

                    <img
                      src={isContrastTheme ? ArrowRightContrast : ArrowRight}
                      alt={t('11027')}
                      className={style.arrowRight}
                    />
                  </>
                ) : null}
                <span className={`${style.regular_text} ${style.bold_text}`}>
                  {returnStationByCode(route.stacjaDoKod, stationsList)}
                  {index !== ticket.odcinki.length - 1 && (
                    <img
                      src={isContrastTheme ? ArrowRightWhite : ArrowRight}
                      alt={t('11027')}
                      className={style.arrowRight}
                    />
                  )}
                </span>
              </Fragment>
            ))}
          </div>
        </>
      )}
      {!isSeasonal && (
        <div className={`${style.ticket_row} ${style.carrier_list_box}`}>
          {ticket.odcinki.map((el) => (
            <div key={el.pociagNr}>
              {returnCarrierLogo(el.pociagKategoriaNazwa, theme)}
              <span className={style.regular_text}>{el.pociagNr}</span>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default MyTicketsSingleTicketUnpaidMobileInnerData;
