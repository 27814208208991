import {FC, useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useAuth} from 'Utils/AuthContext';
import {checkRefreshAndReturnJwt, getJwtTokenOrDefault} from 'Utils/auth';
import {useStyledThemeState} from 'common/theme';

import S from './Footer.module.scss';

import LogoIcon from 'assets/logo.svg';
import SearchIcon from 'assets/search-blue-icon.svg';
import ProfileIcon from 'assets/profile.svg';
import LogoIconContrast from 'assets/logoContrast.svg';
import SearchIconContrast from 'assets/magnifierWhite.svg';
import ProfileIconContrast from 'assets/profileContrastDesktop.svg';
import Menu from 'assets/menu.svg';
import MenuContrast from 'assets/menuContrast.svg';

import {useShoppingBasket} from 'components/pages/PurchasePath/ShoppingBasket/ShoppingBasketContext';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import ShoppingBasketTimeoutModal from 'components/pages/PurchasePath/ShoppingBasket/ShoppingBasketTimeoutModal/ShoppingBasketTimeoutModal';
import {profileMenuElements} from '../../pages/UserProfile/userProfileMenu';
import ShoppingBasketCounterWithBadge from 'components/pages/PurchasePath/ShoppingBasket/ShoppingBasketCounterWithBadge/ShoppingBasketCounterWithBadge';
import {checkIfGuest} from 'Utils/commonFunctions';

const Footer: FC = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const {isContrastTheme} = useStyledThemeState();
  const {userData} = useAuth();
  const localToken = window.sessionStorage.eic2Token;

  const {
    setBasketFromStorage,
    clearBasket,
    clearItemsAfterPayment,
    basketCancelled,
    lessThan2MinutesLeft,
    userSaw2MinutesLeftToPayModal,
    setUserSaw2MinutesLeftToPayModal,
    userSaw0MinutesModal,
    setUserSaw0MinutesModal,
  } = useShoppingBasket();

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [burgerMenuOpen, setBurgerMenuOpen] = useState<boolean>(false);
  const [basketTimeOutModal, setBasketTimeOutModal] = useState<boolean>(false);
  const [cancelBasket, setCancelBasket] = useState<boolean>(false);

  const handleActiveStyle = (path: string) => {
    return location.pathname === path ? S.footerNavItemActive : '';
  };

  const clearBasketState = () => {
    clearItemsAfterPayment();
    clearBasket();
    cancelBasket && setCancelBasket(false);
  };

  const handleBasketModalClick = () => {
    setBasketTimeOutModal(false);
    if (!!cancelBasket && !!basketCancelled) {
      if (!location.pathname.startsWith('/koszyk')) {
        clearBasketState();
      } else {
        setUserSaw0MinutesModal(true);
      }
    } else if (lessThan2MinutesLeft && !userSaw2MinutesLeftToPayModal) {
      setUserSaw2MinutesLeftToPayModal(true);
    }
  };

  const handleMenuClick = () => {
    setBurgerMenuOpen(!burgerMenuOpen);
  };

  const checkToken = async () => {
    const token = await checkRefreshAndReturnJwt();
    if (token) {
      !isLoggedIn && setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  };

  const paymentBasketMode =
    location.pathname.startsWith('/koszyk/platnosc') || location.pathname.startsWith('/pobierzbilet');

  useEffect(() => {
    checkToken();
    localToken && setBasketFromStorage();

    if (!!lessThan2MinutesLeft && !userSaw2MinutesLeftToPayModal && !paymentBasketMode && !basketCancelled) {
      !basketTimeOutModal && setBasketTimeOutModal(true);
    }

    if (!!basketCancelled && !paymentBasketMode && !lessThan2MinutesLeft) {
      !cancelBasket && setCancelBasket(true);
      !basketTimeOutModal && !userSaw0MinutesModal && setBasketTimeOutModal(true);
    }

    if (!!basketCancelled && !paymentBasketMode && !!userSaw0MinutesModal && !location.pathname.startsWith('/koszyk')) {
      clearBasketState();
    }
  }, [
    location.pathname,
    userData,
    localToken,
    lessThan2MinutesLeft,
    paymentBasketMode,
    basketCancelled,
    userSaw0MinutesModal,
  ]);

  useEffect(() => {
    if (!localToken) {
      setIsLoggedIn(false);
    }
  }, [localToken]);

  return (
    <footer className={S.footerWrapper}>
      <nav className={S.footerNav}>
        <ul className={S.footerList}>
          <li>
            <a
              href="https://www.intercity.pl"
              className={`${S.footerNavItem} ${handleActiveStyle('#')}`}
              aria-label={t('10028')}
            >
              <img src={isContrastTheme ? LogoIconContrast : LogoIcon} alt="Logo Intercity" />
              {t('10027')}
            </a>
          </li>
          <li>
            <Link
              to="/"
              className={`${S.footerNavItem} ${isContrastTheme ? S.contrast : S.default} ${handleActiveStyle('/')}`}
            >
              <img src={isContrastTheme ? SearchIconContrast : SearchIcon} alt="Lupa" />
              {t('10002')}
            </Link>
          </li>
          {isLoggedIn && !checkIfGuest() && (
            <li>
              <Link
                to="/koszyk"
                className={`${S.footerNavItem} ${isContrastTheme ? S.contrast : S.default} ${handleActiveStyle('/koszyk')}`}
              >
                <ShoppingBasketCounterWithBadge iconMode={true} />
                {t('29273')}
              </Link>
            </li>
          )}
          <li>
            <Link
              to="/profil"
              className={`${S.footerNavItem} ${isContrastTheme ? S.contrast : S.default} ${handleActiveStyle('/profil')}`}
            >
              <img
                src={isContrastTheme ? ProfileIconContrast : ProfileIcon}
                className={S.profileIcon}
                alt={t('22015')}
              />
              {t('10030')}
            </Link>
          </li>
          <li>
            <div className={`${S.burger}`}>
              <button
                className={S.burgerMenuStyles}
                aria-label={burgerMenuOpen ? t('10013') : t('10014')}
                onClick={handleMenuClick}
              >
                <img src={isContrastTheme ? MenuContrast : Menu} alt="Menu" />
                Menu
              </button>
              <h2 className="hiddenLabel">{t('10015')}</h2>
              <ul className={`${S.burger_menu} ${burgerMenuOpen ? S.burger_menu__open : null}`}>
                {profileMenuElements &&
                  profileMenuElements
                    .filter((menuElement) => {
                      if (getJwtTokenOrDefault('eic2Token')?.scope === 'user') {
                        return menuElement.mobile && !menuElement.hideInMenu && menuElement.name !== 'loginkontogosc';
                      }
                      return menuElement.desktop && !menuElement.hideInMenu && !menuElement.needLogin;
                    })
                    .map((menuElement) => (
                      <li key={menuElement.id}>
                        <a href={menuElement.url} aria-label={t(menuElement.text)}>
                          {t(menuElement.text)}
                        </a>
                      </li>
                    ))}
              </ul>
            </div>
          </li>
        </ul>
      </nav>
      {basketTimeOutModal && (
        <ConfirmationModal
          customHeader={cancelBasket ? t('29260') : t('29261')}
          closeFn={() => {
            handleBasketModalClick();
          }}
          confirmFn={() => {
            handleBasketModalClick();
            navigate(cancelBasket ? '/' : '/koszyk/platnosc');
          }}
          customConfirmBtnTxt={cancelBasket ? t('10002') : t('20002')}
          customCancelBtnTxt={cancelBasket ? t('29002') : t('29262')}
          columnButtons
          fullSizeModal
        >
          <ShoppingBasketTimeoutModal timeOutMode={cancelBasket} />
        </ConfirmationModal>
      )}
    </footer>
  );
};
export default Footer;
