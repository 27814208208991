import {InternationalDiscount, InternationalPersonType} from 'common/api/sale/intlDiscounts';
import {Station} from 'common/api/localData';
import {isForeignStationByCodeEVA} from './station';

export const getForeignDiscounts = (
  departure: Station,
  destination: Station,
  discounts: InternationalDiscount[],
): InternationalDiscount | undefined => {
  if (!destination || !departure || !discounts) return undefined;

  const foreignStation = [destination, departure].find((station) => isForeignStationByCodeEVA(station.kodEVA));

  return discounts.find(
    ({kodZarzadu, osobaTypKod}) =>
      osobaTypKod === InternationalPersonType.child && // currently, the discount offer is intended only for children
      foreignStation?.kodEVA.toString().startsWith(kodZarzadu.toString()),
  );
};
