import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import {env} from './env';
import pl from './locales/pl/translation.json';
import en from './locales/en/translation.json';
import ua from './locales/ua/translation.json';
import {merge} from 'lodash';

const supportedLngs = ['pl', 'en', 'ua'];

const fallbackLng = 'pl';

const resources = {
  pl: {
    translation: pl,
  },
  en: {
    translation: en,
  },
  ua: {
    translation: ua,
  },
};

i18n
  .use(ChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs,
    fallbackLng,
    detection: {
      order: ['localStorage', 'navigator'],
      lookupLocalStorage: 'i18nextLng',
      caches: ['localStorage'],
      excludeCacheFor: ['cimode'],
    },
    backend: {
      backends: [LocalStorageBackend, HttpBackend, resourcesToBackend(resources)],
      backendOptions: [
        {
          store: window.sessionStorage,
        },
        {
          loadPath: env.REACT_APP_TRANSLATIONS_API,
          parse: (data, lng) => {
            return merge(
              resources[lng].translation,
              Object.assign(
                {},
                ...JSON.parse(data)
                  .languages.find((item) => item.language === lng)
                  .translations.map((item) => ({[item.placeholder]: item.textUtf8})),
              ),
            );
          },
        },
      ],
    },
    interpolation: {
      escapeValue: false,
    },
    debug: false,
    react: {
      useSuspense: true,
    },
  });

export default i18n;
