export const TRAIN_DIRECTION = {
  LEFT: 1,
  NO_DATA: 0,
  RIGHT: -1,
};
//electric multiple unit - EZT
export const EMU_TRAIN_TYPES = {
  PENDOLINO: 'ED250',
  FLIRT: 'ED160',
  DART: 'ED161',
  BAUREIHE614: 'SN84',
  DM90: 'SD85',
  PESA_BYDGOSTIA: 'ED74',
};
