import {t} from 'i18next';
import {Day, Month} from '../../../interfaces/calendar';

const checkIfLeap = () => {
  const actualYear = new Date().getFullYear();
  if (actualYear % 4 === 0) {
    if (actualYear % 100 === 0) {
      if (actualYear % 400 === 0) return 29;
      else return 28;
    } else return 29;
  }
  return 28;
};

export const calendarMonthData = [
  {
    index: 0,
    name: '13155',
    days: 31,
  },
  {
    index: 1,
    name: '13156',
    days: checkIfLeap(),
  },
  {
    index: 2,
    name: '13157',
    days: 31,
  },
  {
    index: 3,
    name: '13158',
    days: 30,
  },
  {
    index: 4,
    name: '13159',
    days: 31,
  },
  {
    index: 5,
    name: '13160',
    days: 30,
  },
  {
    index: 6,
    name: '13161',
    days: 31,
  },
  {
    index: 7,
    name: '13162',
    days: 31,
  },
  {
    index: 8,
    name: '13163',
    days: 30,
  },
  {
    index: 9,
    name: '13164',
    days: 31,
  },
  {
    index: 10,
    name: '13165',
    days: 30,
  },
  {
    index: 11,
    name: '13166',
    days: 31,
  },
];

// export const calendarWeekData = ['Pon.', 'Wt.', 'Śr.', 'Czw.', 'Pt.', 'Sob.', 'Nd.'];
export const calendarWeekData = ['11098', '11099', '11100', '11101', '11102', '11103', '11104'];

const checkDayIndex = (index: number, year: number, day: number) => {
  const month = index + 1 < 10 ? `0${index + 1}` : index + 1;
  const dateFormatDay = day < 10 ? `0${day}` : day;
  const date = new Date(`${year}-${month}-${dateFormatDay}`);
  return date.getDay() === 0 ? 6 : date.getDay() - 1;
};

const makeWholeMonthArr = (month: number, year: number, days: number, lastMonthDays: number): Day[] => {
  const daysBefore = checkDayIndex(month, year, 1);
  const daysAfter = 6 - checkDayIndex(month, year, days);

  let uniqueIndex = 0;
  const daysArr = [];
  for (let i = daysBefore - 1; i >= 0; i--) {
    daysArr.push({
      number: lastMonthDays - i,
      symbol: 'start',
      uniqueIndex: `${month}+${uniqueIndex}`,
      monthName: t(calendarMonthData[month - 1 < 0 ? 11 : month - 1]?.name),
    });
    uniqueIndex++;
  }
  for (let i = 1; i <= days; i++) {
    daysArr.push({
      number: i,
      symbol: 'actual',
      uniqueIndex: `${month}+${uniqueIndex}`,
      monthName: calendarMonthData[month].name,
      dayOfWeekIndex: checkDayIndex(month, year, i),
    });
    uniqueIndex++;
  }
  for (let i = 1; i <= daysAfter; i++) {
    daysArr.push({
      number: i,
      symbol: 'end',
      uniqueIndex: `${month}+${uniqueIndex}`,
      monthName: calendarMonthData[month + 1 > 11 ? 0 : month + 1]?.name,
    });
    uniqueIndex++;
  }
  return daysArr;
};

const monthsCounter = (actualMonth: number, dayOfTheMonth: number) => {
  const days = calendarMonthData[actualMonth].days;
  if (dayOfTheMonth > 2) {
    return 2;
  } else if (dayOfTheMonth === 1 && days > 30) {
    return 1;
  }
  return 2;
};

const getLastDayOfMonth = (index: number, dayOfSTart: number, lastMonthDays: number, amountOfDays: number) => {
  if (index === 0) {
    if (amountOfDays - dayOfSTart >= 31) {
      return 31;
    } else if (amountOfDays - dayOfSTart < 31) {
      return amountOfDays;
    }
  }
  return 31 - (lastMonthDays - dayOfSTart) - 1;
};

export const renderData = (): Month[] => {
  const date = new Date();
  const actualMonth = date.getMonth();
  const dayOfTheMonth = date.getDate();

  const renderArr = [];

  const setYear = (index: number, monthIndex: number) => {
    const date = new Date();
    if (index !== 0 && monthIndex === 11) return date.getFullYear() + 1;
    return date.getFullYear();
  };

  for (let i = 0; i < monthsCounter(actualMonth, dayOfTheMonth); i++) {
    let monthIndex = 0;
    if (i === 0) monthIndex = actualMonth;
    else monthIndex = actualMonth === 11 ? 0 : actualMonth + i;
    const lastMonth = monthIndex === 0 ? 11 : monthIndex - 1;

    const month = {
      ...calendarMonthData[monthIndex],
      dayOfStart:
        i === 0 ? calendarMonthData[monthIndex].days - (calendarMonthData[monthIndex].days - dayOfTheMonth) : 1,
      dayOfEnd: getLastDayOfMonth(
        i,
        dayOfTheMonth,
        calendarMonthData[lastMonth].days,
        calendarMonthData[monthIndex].days,
      ),
      monthArr: makeWholeMonthArr(
        monthIndex,
        setYear(i, actualMonth),
        calendarMonthData[monthIndex].days,
        calendarMonthData[lastMonth].days,
      ),
      year: setYear(i, actualMonth),
    };
    renderArr.push(month);
  }
  return renderArr;
};

export const renderSeasonalCalendarData = (): Month[] => {
  const date = new Date();

  const actualMonth = date.getMonth();
  const dayOfTheMonth = date.getDate();

  const renderArr = [];

  const setYear = (index: number, monthIndex: number) => {
    const date = new Date();
    if (index !== 0 && monthIndex === 11) return date.getFullYear() + 1;
    return date.getFullYear();
  };

  for (let i = 0; i < 3; i++) {
    const monthIndex = (actualMonth + i) % 12;
    const lastMonth = monthIndex === 0 ? 11 : monthIndex - 1;

    const month = {
      ...calendarMonthData[monthIndex],
      dayOfStart:
        i === 0 ? calendarMonthData[monthIndex].days - (calendarMonthData[monthIndex].days - dayOfTheMonth) : 1,
      dayOfEnd: getLastDayOfMonth(
        i,
        dayOfTheMonth,
        calendarMonthData[lastMonth].days,
        calendarMonthData[monthIndex].days,
      ),
      monthArr: makeWholeMonthArr(
        monthIndex,
        setYear(i, actualMonth),
        calendarMonthData[monthIndex].days,
        calendarMonthData[lastMonth].days,
      ),
      year: setYear(i, actualMonth),
    };
    renderArr.push(month);
  }
  return renderArr;
};
